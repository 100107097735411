import { Text } from "@clipboard-health/ui-react";
import { Box, Button, Container, TextField } from "@mui/material";
import { WorkedBreakConfirmation } from "@src/app/hcpShifts/components/workedBreakConfirmation";
import { DIGITAL_TIMESHEET_SIGNATURE_STEPS } from "@src/app/hcpShifts/constants";
import { SelectedFile } from "@src/app/shiftSignature/timecard/model";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { WorkedBreakAuthorizationParams } from "@src/appV2/Shifts/Shift/WorkedBreakAuthorization/WorkedBreakRequestCreationPage";
import { Shift, TimeRange } from "@src/lib/interface/src";
import { capitalizeFirstLetterOfEveryWord } from "@src/lib/utils/src";
import { useState } from "react";

import { WorkplaceSignoutModalContent } from "./workplaceSignoutModalContent";

export interface SignatureConfirmationProps {
  onSubmit: (file: SelectedFile, facilityEmployeeName: string) => void;
  lunchInOutTimeRange: TimeRange;
  isUploadingImage: boolean;
  isShiftTimeEditOpen: boolean;
  isSolveUnpaidBreaksEnabled: boolean;
  workedBreakAuthorization?: WorkedBreakAuthorizationParams;
  onStepChange: (currentStep: DIGITAL_TIMESHEET_SIGNATURE_STEPS) => void;
  onOpenTimeEdit: () => void;
  isPassPhoneOpen: boolean;
  clockInOut: TimeRange;
  shift: Shift;
  setIsOnFinalStageOfSigningFlow: (value: boolean) => void;
}

export function SignatureConfirmationRevamp(props: SignatureConfirmationProps) {
  const {
    onSubmit,
    lunchInOutTimeRange,
    isUploadingImage,
    isShiftTimeEditOpen,
    isSolveUnpaidBreaksEnabled,
    workedBreakAuthorization,
    onStepChange,
    onOpenTimeEdit,
    isPassPhoneOpen,
    clockInOut,
    shift,
    setIsOnFinalStageOfSigningFlow,
  } = props;

  const [facilityEmployeeName, setFacilityEmployeeName] = useState<string>("");
  const [isWorkedBreakStatementConfirmed, setIsWorkedBreakStatementConfirmed] = useState(false);
  const [currentStep, setCurrentStep] = useState<DIGITAL_TIMESHEET_SIGNATURE_STEPS>(
    DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCP_SIGNATURE
  );
  useLogEffect(APP_V2_USER_EVENTS.NEW_SIGNING_FLOW_WITH_FEEDBACK_MODAL_OPENED, {
    shiftId: shift._id,
    facilityId: shift.facilityId,
  });

  const goToStep = (step: DIGITAL_TIMESHEET_SIGNATURE_STEPS) => {
    setCurrentStep(step);
    onStepChange(step);
  };

  if (isPassPhoneOpen) {
    return null;
  }

  return (
    <Container>
      {currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCP_SIGNATURE && (
        <Box
          sx={{
            textAlign: "center",
            my: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Text variant="body1" sx={{ mb: 2 }}>
            Please ask a workplace employee{" "}
            <Box component="span" sx={{ fontWeight: "bold" }}>
              you worked with
            </Box>{" "}
            to verify your times and sign
          </Text>

          <TextField
            fullWidth
            label="Name"
            placeholder="Workplace Employee Name"
            value={facilityEmployeeName}
            onChange={(e) => {
              setFacilityEmployeeName(
                e.target.value ? capitalizeFirstLetterOfEveryWord(e.target.value) : ""
              );
            }}
            sx={{ mb: 2 }}
          />

          <Button
            variant="contained"
            disabled={
              !/[a-zA-Z]/.test(facilityEmployeeName) ||
              (isSolveUnpaidBreaksEnabled && isShiftTimeEditOpen)
            }
            onClick={() => goToStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_SIGNATURE)}
            sx={{ borderRadius: 28 }}
          >
            Continue
          </Button>
        </Box>
      )}

      {workedBreakAuthorization &&
        currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_BREAK_CONFIRMATION && (
          <WorkedBreakConfirmation
            isTimeEditOpen={isShiftTimeEditOpen}
            lunchInOutTimeRange={lunchInOutTimeRange}
            workedBreakAuthorization={workedBreakAuthorization}
            onEditTimesClick={onOpenTimeEdit}
            onConfirmClick={() => {
              setIsWorkedBreakStatementConfirmed(true);
              setCurrentStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_SIGNATURE);
              setIsOnFinalStageOfSigningFlow(true);
            }}
          />
        )}

      {(currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_SIGNATURE ||
        (currentStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_BREAK_CONFIRMATION &&
          !workedBreakAuthorization)) && (
        <WorkplaceSignoutModalContent
          onSubmit={(file: SelectedFile) => {
            onSubmit(file, facilityEmployeeName);
          }}
          onStrokeStarted={() => {
            if (workedBreakAuthorization && !isWorkedBreakStatementConfirmed) {
              goToStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_BREAK_CONFIRMATION);
            }
          }}
          onBackClick={() => {
            setIsWorkedBreakStatementConfirmed(false);
            goToStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCP_SIGNATURE);
          }}
          isUploadingImage={isUploadingImage}
          clockInOut={clockInOut}
          lunchInOut={lunchInOutTimeRange}
          facilityEmployeeName={facilityEmployeeName}
          shift={shift}
        />
      )}
    </Container>
  );
}
