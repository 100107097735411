import { isDefined } from "@clipboard-health/util-ts";
import { IonButton, IonInput, IonItem, IonLabel } from "@ionic/react";
import { hideKeyboardOnKeyPressEnter } from "@src/app/common/ionInputHooks/hideKeyboardOnKeyPress";
import { capitalizeFirstLetterOfEveryWord } from "@src/lib/utils/src";

import { DIGITAL_TIMESHEET_SIGNATURE_STEPS } from "../../constants";

export const HcfEmployeeNameInput = ({
  form,
  actions,
}: {
  form: {
    hcfEmployeeName: string;
    setHcfEmployeeName: (n: string) => void;
  };
  actions: {
    goToStep: (nextStep: DIGITAL_TIMESHEET_SIGNATURE_STEPS) => void;
  };
}) => {
  const { hcfEmployeeName, setHcfEmployeeName } = form;
  const { goToStep } = actions;
  return (
    <div id="hcfEmpNameSection">
      <div className="timesheet-summary-text signature-confirmation-prompt">
        <span>Please ask a workplace employee to</span>
        <br />
        <span>verify your times and sign</span>
      </div>
      <div className="signature-employee-padding-keyboard">
        <IonItem lines="none" className="signature-employee-division">
          <IonLabel position="fixed" className="signature-employee-name-label">
            Name
          </IonLabel>
          <IonInput
            className="signature-employee-name signature-employee-input"
            placeholder="Workplace Employee Name"
            value={hcfEmployeeName}
            type="text"
            onKeyPress={hideKeyboardOnKeyPressEnter}
            onIonChange={(e) => {
              setHcfEmployeeName(
                isDefined(e.detail.value) ? capitalizeFirstLetterOfEveryWord(e.detail.value) : ""
              );
            }}
            clearInput
          ></IonInput>
        </IonItem>
      </div>
      <div className="sign-button-container">
        <IonButton
          fill="outline"
          shape="round"
          className="ion-margin sign-button"
          onClick={() => goToStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCP_SIGNATURE)}
        >
          Back
        </IonButton>
        <IonButton
          fill="solid"
          shape="round"
          color="primary"
          disabled={!/[a-zA-Z]/.test(hcfEmployeeName)}
          className="ion-margin sign-button sign-confirmation-continue-button"
          onClick={() => goToStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_INFO)}
        >
          Continue
        </IonButton>
      </div>
    </div>
  );
};
